// extracted by mini-css-extract-plugin
export var bottom = "ContactMain-module--bottom--bc5fc";
export var bottomHeading = "ContactMain-module--bottom-heading--a03bd";
export var bottomInner = "ContactMain-module--bottom-inner--134d3";
export var contactFormField = "ContactMain-module--contact-form-field--1889b";
export var contactMethod = "ContactMain-module--contact-method--1a7de";
export var contactMethodHeading = "ContactMain-module--contact-method-heading--45d57";
export var formContainer = "ContactMain-module--form-container--bda2a";
export var main = "ContactMain-module--main--621fc";
export var mainContent = "ContactMain-module--mainContent--830ed";
export var primaryHeading = "ContactMain-module--primaryHeading--74174";
export var secondaryHeading = "ContactMain-module--secondaryHeading--2cb8b";
export var textContent = "ContactMain-module--textContent--65df5";
export var top = "ContactMain-module--top--22ec1";