import React from 'react';
import * as style from '../../../styles/general.module.scss'
import * as styles from './getUpdates.module.scss'


export default function Updates ({ data }) {
    return (
        <div className={styles.getUpdates}>
            <div className={style.layout}>
                <div className={styles.getUpdatesInner}>

                    <div className={styles.getUpdatesLeft}>
                        <h1>{data.header}</h1>
                        <p>{data.description}</p>
                    </div>
                    <div className={styles.getUpdatesRight}>
                        <form action="https://cloudwalk.us5.list-manage.com/subscribe/post?u=8cce679351abee1ccb782c361&amp;id=5e3232c6d5" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate={true}>

                            <input type="email" placeholder="Email" name="EMAIL" id="mce-EMAIL" className={`required email ${styles.updatesEmail}`}></input>

                            <input className={styles.updatesSubmit} type="submit" value="Subscribe" name="subscribe"></input>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
