import React from 'react';
import * as styles from './Btn.module.scss'

export default function Btn({url, label, type}) {
  let buttonType = 'link'

  if (type) {
    buttonType = type
  }

  return (
    <div>
      {buttonType === 'button' && (
        <button href={url} className={styles.btn}>
          <span>
              {label}
          </span>
        </button>
      )}

      {buttonType === 'link' && (
        <a href={url} className={styles.btn}>
          <span>
              {label}
          </span>
        </a>
      )}
    </div>
  )
}
