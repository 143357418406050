import React from "react";
import * as styles from './FormLabel.module.scss'

export default function FormLabel({label, inputId}) {
  return (
    <div>
      <label className={styles.label} htmlFor={inputId}>
        {label}
      </label>
    </div>
  )
}
