import React from 'react'
import * as styles from './FormInput.module.scss'
import { uniqueId } from 'lodash'
import FormLabel from "../FormLabel/FormLabel";

export default function FormInput({label, name, placeholder, type, required}) {
  const inputId = uniqueId('input')
  let isRequired = false
  let formType = 'text'

  if(type) {
    formType = type
  }

  if(required) {
    isRequired = required
  }

  return (
    <div>

      <FormLabel label={label}
                 inputId={inputId}/>

      <input className={styles.formControl}
             placeholder={placeholder}
             id={inputId}
             required={isRequired}
             name={name}
             type={formType}/>

    </div>
  )
}
