import React from 'react';
import * as styles from './BlockquoteHero.module.scss'
import Logomark from '../../../images/cloudwalk-logomark.svg'


export default function BlockquoteHero({data}) {
  const blockquoteClasses = [styles.blockquote]

  if(data.backgroundColor === 'cloud') {
    blockquoteClasses.push(styles.blockquoteCloud)  
  } 
  else if(data.backgroundColor === 'mineral') {
    blockquoteClasses.push(styles.blockquoteMineral)
  }
  else{
    blockquoteClasses.push(styles.blockquoteThermal)
  }

  return (
    <div className={styles.section}>
      <div className="wrapper">
        <div className={blockquoteClasses.join(' ')}>
          <div className={styles.iconContainer}>
            <Logomark/>
          </div>
          <div className={styles.quote}>{data.quote}</div>
          <div className={styles.cite}>&mdash;{data.author}</div>
        </div>
      </div>
    </div>
  )
}
