import React from 'react'
import * as styles from './ContactMain.module.scss'
import BlockquoteHero from "../pagebuilderComponents/BlockquoteHero/BlockquoteHero";
import Btn from "../btn/Btn";
import FormInput from "../FormInput/FormInput";
import FormTextarea from "../FormTextarea/FormTextarea";
import Updates from "../pagebuilderComponents/getUpdates/getUpdates";


export default function ContactMain({data}) {
  const blockquoteData = {
    theme: 'Thermal',
    quote: '“Before you can ever hear the word go you must hear the word come.”',
    author: 'Bonnke',
  }

  const updatesData = {
    header: 'Get Updates',
    description: 'Be the first to get updates from Cloudwalk… '
  }

  return (
    <div>
      <div className={styles.top}>
        <div className="wrapper">
          <div className={styles.main}>
            <div className={styles.mainContent}>
              <h1 className={styles.primaryHeading}>
                We&rsquo;d love to hear from you.
              </h1>

              <h2 className={styles.secondaryHeading}>
                Main Contact
              </h2>

              <div className={styles.textContent}>
                <p>
                  <a href="mailto:support@cloudwalk.org">support@cloudwalk.org</a>
                </p>

                <p>
                  37 Church Street<br/>
                  Alpharetta, GA 30009<br/>
                  (404) 834-2515
                </p>
              </div>
            </div>

            <div className={styles.formContainer}>
              <form action="https://submit-form.com/AvgqV1Ak">
                  <div className={styles.contactFormField}>
                    <FormInput label="Full Name" name="full_name" placeholder="Your Name"/>
                  </div>

                  <div className={styles.contactFormField}>
                    <FormInput label="Email" name="email" type="email" placeholder="Email Address" required={true}/>
                  </div>
                  <div className={styles.contactFormField}>
                    <FormTextarea label="Message" name="message" placeholder="Your message..."/>
                  </div>

                  <Btn label="Send Message" type="button"/>
              </form>
            </div>
          </div>
        </div>
      </div>

      <BlockquoteHero data={blockquoteData}/>


      <Updates data={updatesData}/>
    </div>
  )
}
