import React from 'react'
import Layout from '../components/Layout'
import ContactMain from "../components/ContactMain/ContactMain";
import { Helmet } from 'react-helmet';

const PageTemplate = props => {
  const {data} = props
  console.log('data', data)

  return (
    <Layout>
      <Helmet>
      <meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;" />
        <meta charSet="utf-8" />
        <title>Contact-Cloudwalk</title>
        <meta name="referrer" content="origin" />
      </Helmet> 
      <ContactMain/>
    </Layout>
  )
}

export default PageTemplate
