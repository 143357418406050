import React from "react";
import * as styles from './FormTextarea.module.scss'
import {uniqueId} from "lodash";
import FormLabel from "../FormLabel/FormLabel";

export default function FormTextarea({label, name, placeholder, required}) {
  const inputId = uniqueId('textarea')
  let isRequired = false

  if (required) {
    isRequired = required
  }

  return (
    <div>

      <FormLabel label={label}
                 inputId={inputId}/>

      <textarea className={styles.formControl}
                placeholder={placeholder}
                id={inputId}
                required={isRequired}
                name={name}/>

    </div>
  )
}
